var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.mensaje.satisfactorio.status || _vm.mensaje.nosatisfactorio.status )?_c('div',{staticClass:"sticky top-0 z-50"},[_c('NotificacionInterna',{attrs:{"mensaje":_vm.mensaje}})],1):_vm._e(),_c('div',{},[_c('div',[_c('v-data-table',{staticClass:"elevation-1 mb-10",attrs:{"headers":_vm.headers,"items":_vm.fields_corporativas.group_area_formacion.json_areas_formacion.json_areas_formacion,"sort-by":"nu_id_padre","loading":_vm.isLoading,"loading-text":"Cargando... Por favor espere"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Crear área ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('ValidationObserver',{ref:"datos_basicos_area_formacion"},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_corporativas.group_area_formacion.tx_descripcion.tx_descripcion","rules":_vm.fields_corporativas.group_area_formacion.tx_descripcion.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg pt-2",attrs:{"outlined":"","error-messages":errors,"label":_vm.fields_corporativas.group_area_formacion.tx_descripcion.caracteristicas.label,"disabled":!_vm.fields_corporativas.isEditing},model:{value:(_vm.fields_corporativas.group_area_formacion.tx_descripcion.tx_descripcion),callback:function ($$v) {_vm.$set(_vm.fields_corporativas.group_area_formacion.tx_descripcion, "tx_descripcion", $$v)},expression:"fields_corporativas.group_area_formacion.tx_descripcion.tx_descripcion"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_corporativas.group_area_formacion.tx_descripcion.caracteristicas.tooltip)+" ")])])],1),_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_corporativas.group_area_formacion.co_area_formacion.co_area_formacion","rules":_vm.fields_corporativas.group_area_formacion.co_area_formacion.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg pt-2",attrs:{"outlined":"","error-messages":errors,"label":_vm.fields_corporativas.group_area_formacion.co_area_formacion.caracteristicas.label,"disabled":!_vm.fields_corporativas.isEditing},model:{value:(_vm.fields_corporativas.group_area_formacion.co_area_formacion.co_area_formacion),callback:function ($$v) {_vm.$set(_vm.fields_corporativas.group_area_formacion.co_area_formacion, "co_area_formacion", $$v)},expression:"fields_corporativas.group_area_formacion.co_area_formacion.co_area_formacion"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_corporativas.group_area_formacion.co_area_formacion.caracteristicas.tooltip)+" ")])])],1),_c('div',[_c('validation-provider',{attrs:{"name":"fields_corporativas.group_area_formacion.tx_tipo.tx_tipo","rules":_vm.fields_corporativas.group_area_formacion.tx_tipo.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-group--focused text-lg",attrs:{"items":_vm.list_tipos,"item-text":"tx_tipo","item-value":"co_tipo","placeholder":_vm.fields_corporativas.group_area_formacion.tx_tipo.caracteristicas.placeholder,"error-messages":errors,"label":_vm.fields_corporativas.group_area_formacion.tx_tipo.caracteristicas.label,"outlined":"","disabled":!_vm.fields_corporativas.isEditing},model:{value:(_vm.fields_corporativas.group_area_formacion.tx_tipo.tx_tipo),callback:function ($$v) {_vm.$set(_vm.fields_corporativas.group_area_formacion.tx_tipo, "tx_tipo", $$v)},expression:"fields_corporativas.group_area_formacion.tx_tipo.tx_tipo"}})]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"fields_corporativas.group_area_formacion.co_status.co_status","rules":_vm.fields_corporativas.group_area_formacion.co_status.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-group--focused text-lg",attrs:{"items":_vm.list_status,"item-text":"tx_status","item-value":"co_status","placeholder":_vm.fields_corporativas.group_area_formacion.co_status.caracteristicas.placeholder,"error-messages":errors,"label":_vm.fields_corporativas.group_area_formacion.co_status.caracteristicas.label,"outlined":"","disabled":!_vm.fields_corporativas.isEditing},model:{value:(_vm.fields_corporativas.group_area_formacion.co_status.co_status),callback:function ($$v) {_vm.$set(_vm.fields_corporativas.group_area_formacion.co_status, "co_status", $$v)},expression:"fields_corporativas.group_area_formacion.co_status.co_status"}})]}}])})],1)])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Esta seguro de borrar este registro?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("No")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Si")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v(" No hay información ")])]},proxy:true}])})],1)]),_c('div',{staticClass:"flex justify-end"},[_c('div',[_c('button',{staticClass:"hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1",on:{"click":function($event){return _vm.cancelar()}}},[_vm._v(" Cerrar ")]),_c('button',{staticClass:"flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1",on:{"click":function($event){return _vm.cancelar()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiStopCircleOutline)+" ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }