<template>
  <div class="">
        <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
          <NotificacionInterna :mensaje="mensaje" />  
        </div>
        <div class="">
            <div>              
                <v-data-table
                  :headers="headers"
                  :items="fields_corporativas.group_area_formacion.json_areas_formacion.json_areas_formacion"
                  sort-by="nu_id_padre"
                  class="elevation-1 mb-10"
                  :loading="isLoading"
                  loading-text="Cargando... Por favor espere"                    

                >

                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialog"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Crear área                                  
                                </v-btn>

                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                  <ValidationObserver ref="datos_basicos_area_formacion">

                                        <div>
                                            <v-tooltip right>
                                              <template v-slot:activator="{ on, attrs }">
                                                <validation-provider
                                                  v-slot="{ errors }"
                                                  name="fields_corporativas.group_area_formacion.tx_descripcion.tx_descripcion"
                                                  :rules=fields_corporativas.group_area_formacion.tx_descripcion.caracteristicas.required
                                                >
                                                    <v-text-field
                                                      v-model="fields_corporativas.group_area_formacion.tx_descripcion.tx_descripcion"
                                                      outlined
                                                      class="input-group--focused text-lg pt-2"
                                                      :error-messages="errors"
                                                      :label=fields_corporativas.group_area_formacion.tx_descripcion.caracteristicas.label
                                                      v-bind="attrs"
                                                      v-on="on"
                                                      :disabled=!fields_corporativas.isEditing                            
                                                    ></v-text-field>

                                                </validation-provider>   
                                              </template>
                                              <span>{{fields_corporativas.group_area_formacion.tx_descripcion.caracteristicas.tooltip  }} </span>
                                            </v-tooltip>                

                                        </div>  
                                        <div>
                                            <v-tooltip right>
                                              <template v-slot:activator="{ on, attrs }">

                                            <validation-provider
                                              v-slot="{ errors }"
                                              name="fields_corporativas.group_area_formacion.co_area_formacion.co_area_formacion"
                                              :rules=fields_corporativas.group_area_formacion.co_area_formacion.caracteristicas.required
                                            >
                                              <v-text-field
                                                v-model="fields_corporativas.group_area_formacion.co_area_formacion.co_area_formacion"
                                                outlined
                                                class="input-group--focused text-lg pt-2"
                                                :error-messages="errors"
                                                :label=fields_corporativas.group_area_formacion.co_area_formacion.caracteristicas.label
                                                v-bind="attrs"
                                                v-on="on"
                                                :disabled=!fields_corporativas.isEditing                            
                                              ></v-text-field>
                                            </validation-provider>     
                                              </template>
                                              <span>{{fields_corporativas.group_area_formacion.co_area_formacion.caracteristicas.tooltip  }} </span>
                                            </v-tooltip>                
                                        </div>
                                        <div>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="fields_corporativas.group_area_formacion.tx_tipo.tx_tipo"
                                            :rules=fields_corporativas.group_area_formacion.tx_tipo.caracteristicas.required
                                          >
                                                  <v-select
                                                    v-model="fields_corporativas.group_area_formacion.tx_tipo.tx_tipo"
                                                    :items="list_tipos"
                                                    item-text="tx_tipo"
                                                    item-value="co_tipo"
                                                    class="input-group--focused text-lg"
                                                    :placeholder=fields_corporativas.group_area_formacion.tx_tipo.caracteristicas.placeholder
                                                    :error-messages="errors"
                                                    :label=fields_corporativas.group_area_formacion.tx_tipo.caracteristicas.label
                                                    outlined
                                                    :disabled=!fields_corporativas.isEditing
                                                  ></v-select>                      

                                          </validation-provider>                    
                                        </div>  
                                        <div>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="fields_corporativas.group_area_formacion.co_status.co_status"
                                            :rules=fields_corporativas.group_area_formacion.co_status.caracteristicas.required
                                          >
                                                  <v-select
                                                    v-model="fields_corporativas.group_area_formacion.co_status.co_status"
                                                    :items="list_status"
                                                    item-text="tx_status"
                                                    item-value="co_status"
                                                    class="input-group--focused text-lg"
                                                    :placeholder=fields_corporativas.group_area_formacion.co_status.caracteristicas.placeholder
                                                    :error-messages="errors"
                                                    :label=fields_corporativas.group_area_formacion.co_status.caracteristicas.label
                                                    outlined
                                                    :disabled=!fields_corporativas.isEditing
                                                  ></v-select>                      

                                          </validation-provider>                    
                                        </div>  


                                  </ValidationObserver>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Guardar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5">Esta seguro de borrar este registro?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
                                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Si</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      {{ icons.mdiPencil }}                                            
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      {{ icons.mdiDelete }}                                            
                      
                    </v-icon>
                  </template>
                  <template v-slot:no-data>
                    <span>
                      No hay información
                    </span>
                  </template>
              </v-data-table>
            </div>

        </div>                  


                <!-- Inicio Boton de Navegacion -->
            <div class="flex justify-end">

                <div>
                        <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                    Cerrar
                        </button>                          
                        <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiStopCircleOutline }}
                                        </v-icon>
                        </button>                          
                    
                </div>
            </div>
            <!-- Fin Boton de Navegacion -->    

       
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { CREATE_AREA_FORMACION, LIST_AREAS_FORMACION } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";

import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'AreaFormacion',
  data () {
    return {
          mensaje: {
            satisfactorio: {
              status: false,
              texto: ''
            },
            nosatisfactorio: {
              status: false,
              texto: ''
            },
          },
        list_status: [
          {
            co_status: 'activo',
            tx_status: 'Activo'
          },
          {
            co_status: 'noactivo',
            tx_status: 'No Activo'
          },
          
        ],
        list_tipos: [
          {
            co_tipo: 'oficial',
            tx_tipo: 'Oficial'
          },
          {
            co_tipo: 'nooficial',
            tx_tipo: 'No Oficial'
          }
        ],
        list_area_formacion: [],
        isLoading: false,
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          tx_descripcion: '',
          json_especialidad: '',
        },  
        defaultItem: {
          tx_descripcion: [],
          json_especialidad: [],
          caracteristicas: {
            nombre: 'tx_descripcion',
            label: 'Institución *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la institución',
            tooltip: 'Institución',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },
        institucion_especialidad: {},
        items_data_table: [],   
        headers: [
            {
              text: 'Código',
              align: 'start',
              sortable: false,
              value: 'co_area_formacion',
            },
            { text: 'Descripción', value: 'tx_descripcion' },        
            { text: 'Actions', value: 'actions', sortable: false },
        ],
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
  },     
  computed: {
    ...mapGetters(['fields_corporativas']),
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Item' : 'Editar Item'
    },
  },
  props: {
    send_datos: Object
  },
  components: {
    NotificacionInterna
  },    
  methods: {
    searchAreaFormacion(q) {
        this.$store.dispatch(LIST_AREAS_FORMACION, {q: q}).then(response => {
          this.isLoading = true
          this.fields_corporativas.group_area_formacion.json_areas_formacion.json_areas_formacion = []
          for (const item in response.rest.items) {
              this.fields_corporativas.group_area_formacion.json_areas_formacion.json_areas_formacion.push({
                co_area_formacion: response.rest.items[item].co_area_formacion,
                tx_descripcion: response.rest.items[item].tx_descripcion,
              })
          }
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        }).finally(() => (
            setTimeout(() => {
              this.isLoading = false
            },3000)               
        ))  
        
        
        
      },
  
      close () {
        this.dialog = false
        this.$nextTick(() => {
          //this.cleanig()
          //this.fields_corporativas.group_area_formacion.json_areas_formacion.json_areas_formacion = []
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          //this.institucion_especialidad = Object.assign({}, this.fields_corporativas.group_area_formacion.tx_descripcion.tx_descripcion)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.items_niveles[this.editedIndex], this.institucion_especialidad)
        } else {
          console.log('this.editedItem: ', this.fields_corporativas.group_area_formacion.co_area_formacion.co_area_formacion)
          /*var aa = {
            tx_descripcion: this.institucion_especialidad.tx_descripcion,
            json_especialidad: this.institucion_especialidad.json_especialidad
          }
          */
          //this.fields_corporativas.tx_descripcion.json_especialidad = this.institucion_especialidad.json_especialidad
          this.fields_corporativas.group_area_formacion.json_areas_formacion.json_areas_formacion.push({
            co_area_formacion: this.fields_corporativas.group_area_formacion.co_area_formacion.co_area_formacion,
            tx_descripcion: this.fields_corporativas.group_area_formacion.tx_descripcion.tx_descripcion,
            tx_tipo: this.fields_corporativas.group_area_formacion.tx_tipo.tx_tipo,
            co_status: this.fields_corporativas.group_area_formacion.co_status.co_status,
          })


            //this.buscarPersona('params')

            const formData = new FormData()
            formData.append('area_formacion', JSON.stringify(this.fields_corporativas.group_area_formacion.json_areas_formacion.json_areas_formacion))

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

            //formData = JSON.parse(JSON.stringify(guardar.formData))

            formData.append('oper', 'REGISTRO')

            this.$store.dispatch(CREATE_AREA_FORMACION, formData).then(datos => { 
                  this.mensaje.satisfactorio.texto = "El área de formación " + JSON.parse(datos.datos_area_formacion).co_area_formacion + " fue asociada satisfactoriamente"
                  this.mensaje.satisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_corporativas));
                        console.log('message emit from child component01: ', datos)
                        this.mensaje.satisfactorio.status = false
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Datos  creado satisfactoriamente!'
                        });      

                    },3000)                  
                    
                    this.$emit("guardar_area_formacion", this.fields_corporativas.group_area_formacion.json_areas_formacion.json_areas_formacion);              
                    
                    //this.$refs.tabs_ubigeo_tabJustify.click();

                  
              }, error => {
                  this.$store.commit(SET_ERROR, error.statusText)
                  this.mensaje.nosatisfactorio.texto = "Registro de prototipo cancelado"
                  this.mensaje.nosatisfactorio.status = true
                  this.ocultar_pestana()
                  this.mostrar_pestana('collapseMotivo')
                  setTimeout(() => {
                      this.mensaje.nosatisfactorio.status = false
                      },3000);             

              })  

          /// hasta aca optimizar


          //this.fields_corporativas.group_area_formacion.json_areas_formacion.json_areas_formacion = this.items_data_table
          console.log('this.editedItem items_data_table: ', this.fields_corporativas.group_area_formacion.json_areas_formacion.json_areas_formacion)
          //this.fields_corporativas.group_area_formacion.tx_descripcion.tx_descripcion.push(this.institucion_especialidad.tx_descripcion)
        }
        this.close()
      },    
      initialize () {
        this.institucion_especialidad = this.defaultItem
      },

      editItem (item) {
        //this.searchInstitucion(item.tx_descripcion.nu_id_institucion)
        this.editedIndex = this.fields_corporativas.group_area_formacion.json_areas_formacion.json_areas_formacion.indexOf(item)
        const claves_primarias = {}
        claves_primarias.co_area_formacion = 'co_area_formacion'
        for (const property in item) {
            //console.log('item: ', property)
            if (claves_primarias[property] != property) {
              this.fields_corporativas.group_area_formacion[property][property] = item[property]
            }               
        }

        this.dialog = true
      },
      cleanig () {
        const claves_primarias = {}
        claves_primarias.json_areas_formacion = 'json_areas_formacion'

        for (const property in this.fields_corporativas.group_area_formacion) {
            if (claves_primarias[property] != property) {
              const myArray = property.split("_"); 
              if (myArray[0] === 'JSON') {
                this.fields_corporativas.group_area_formacion[property][property] = []
              } else {
                this.fields_corporativas.group_area_formacion[property][property] = ''
              }          
            }          
        }

      },
      deleteItem (item) {
        console.log('deleteItem: ', item)
        this.editedIndex = this.fields_corporativas.group_area_formacion.json_areas_formacion.json_areas_formacion.indexOf(item)
        console.log('editedIndex: ', this.editedIndex)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.fields_corporativas.group_area_formacion.json_areas_formacion.json_areas_formacion.splice(this.editedIndex, 1)
        this.closeDelete()
      },

    remove (item) {
      var index = this.institucion_especialidad.json_especialidad.findIndex(i => i.nu_id_especialidad === item.nu_id_especialidad);
      if (index >= 0) this.institucion_especialidad.json_especialidad.splice(index, 1)
    },
      
    async guardar () {
          console.log('Guardar: ')
          var isValid = false
          if (this.fields_corporativas.group_area_formacion.json_areas_formacion.json_areas_formacion) {
            isValid = true
          }
          if (isValid) {
            this.$emit("guardar_anoescolar_institucion", JSON.stringify(this.fields_corporativas.group_area_formacion.json_areas_formacion.json_areas_formacion));
          }          


        // const isValid = await this.$refs.datos_basicos_area_formacion.validate()
        // if (!isValid) {
        //     return true
        // }
    },              
    async cancelar () {
    //this.loading4 = true 
        this.$emit("guardar_anoescolar_institucion", JSON.stringify(this.fields_corporativas));

    //this.fields_historia_clinica.isEditing = !this.fields_historia_clinica.isEditing     

    }, 
          


  },
  
  mounted() {
    this.searchAreaFormacion();
    //this.fields_corporativas.group_area_formacion.co_area_formacion.co_area_formacion = this.send_datos.AnoEscolar.co_area_formacion
    //this.searchInstituciones()
    //this.searchNiveles()
    //this.searchAnoInstitucion(this.send_datos.AnoEscolar.nu_id_ano_escolar)
    if (this.send_datos.operacion === 'EDITAR') {
        //this.fields_corporativas.group_area_formacion.tx_descripcion.tx_descripcion = JSON.parse(this.send_datos.usuario.tx_descripcion)
        //this.fields_corporativas.group_area_formacion.json_especialidad.json_especialidad = JSON.parse(this.send_datos.usuario.json_especialidad)
          // for (var prop in this.send_datos.usuario) {
          //     if(typeof this.fields_corporativas[prop] === 'object') {
          //           if (this.fields_corporativas[prop]['caracteristicas']['tipo'] === 'JSON') {
          //             this.fields_corporativas[prop][prop] = JSON.parse(this.send_datos.usuario[prop])
          //           } else {
          //             this.fields_corporativas[prop][prop] = this.send_datos.usuario[prop]
          //           }
          //     } else {
          //           this.fields_corporativas[prop][prop] = this.send_datos.usuario[prop]
          //     }
          // }
    } else {
        //this.limpiar()
        //this.list_especialidades = JSON.parse(this.fields_corporativas.group_area_formacion.tx_descripcion.tx_descripcion[0].json_especialidad)
        //this.fields_corporativas.group_area_formacion.json_areas_formacion.json_areas_formacion = []
        this.fields_corporativas.isEditing = true
    }           
  },
    
}
</script>