<template>
    <div class="text-skin-base w-full">
        <AreaFormacion titulo="" @guardar_area_formacion="return_area_formacion" :send_datos="send_datos"/>                  

    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus

} from '@mdi/js'
import { reactive  } from "@vue/composition-api"
import {   LIST_PERFIL } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import AreaFormacion from "@/components/configuracion/AreaFormacion/AreaFormacion.vue";

export default {
    name: 'PerfilOpciones',
    data() {
        return {
            dialogPerfil: false,
            buscarPaciente: false, 
            send_datos: {},
            isLoading: false,
        }
    },
    props: {
        titulo: String

    },
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus
        });  


        return {
        icons
        }
    },
    methods: {
        searchPerfil(q) {
          console.log('Paciente: ', q)
          this.$store.dispatch(LIST_PERFIL, {nu_id_perfil: q}).then(response => {
              this.consultasmedica = response.rest.items
          }, error => {
              this.$store.commit(SET_ERROR, error.statusText)
          })      
        },  
        registrar_perfil () {
            this.dialogPerfil = true
            this.send_datos.operacion = 'REGISTRAR'
            this.fields_perfil.isEditing = true
            // console.log(value) // someValue
        },
        return_area_formacion(msg) { 
   
            this.$emit("guardar_area_formacion", msg);
            this.dialogPerfil = false
        },    

    },
    computed: {
        ...mapGetters(['fields_perfil','fields_persona','currentUser']),
    },
    components: {
        AreaFormacion,
    },
    mounted() {
        this.searchPerfil()
    },     
}
</script>