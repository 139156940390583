 <template>
  <div class="w-full p-2">
      <div>
        <AreaFormacionHeader :titulo="titulo" /> 
      </div>  

      <div>
        <AreaFormacionOpciones @guardar_area_formacion="return_opciones" :titulo="titulo"/>
      </div>

  </div>
</template>

<script>
import AreaFormacionOpciones from "@/views/configuracion/AreaFormacion/AreaFormacionOpciones.vue";
import AreaFormacionHeader from "@/components/comunes/HeaderModulo.vue";

import {  SET_ERROR } from '@/store/mutations.type'
import { LIST_ANOS } from '@/store/actions.type'

export default {
  name: 'AreaFormacion',
  data () {
    return {
      titulo: 'Área de formación',
      datos_academia: [],
      send_datos: {
        list_anos: []
      },

    }
  },
  setup() {
  },  
  computed: {
  },
  components: {
    AreaFormacionOpciones,
    AreaFormacionHeader    
  //  ThemifyIcon    
  },  
  watch: {

  },  
  methods: {
        searchAnos(q) {
          this.$store.dispatch(LIST_ANOS, {q: q}).then(response => {
              this.send_datos.list_anos = response.rest.items
          }, error => {
              this.$store.commit(SET_ERROR, error.statusText)
          })      
        },

    return_opciones(msg) {
        console.log("return_opciiones: " ,msg);
      this.searchAnos({q: msg})

      //this.send_academia = msg;
    },      
    return_consulta_medica(msg) {
        //this.buscarPersona('params')
        var guardar_academia = msg;
        //this.select_academia = JSON.parse(msg);
        console.log("return_consulta_medica guardar_academia update: " , guardar_academia);

        
        if (guardar_academia.btn_guardar == 'true') {
            this.searchAcademia({})
            //this.fields.academia = this.select_academia.nu_id_representante
            console.log("Guarda ultima medica: " + guardar_academia.btn_guardar);
        } else {
            console.log("Cancelar: " + guardar_academia.btn_guardar);
        }
        this.dialogConsultaMedica = false
        //this.send_academia = msg;
    },        

  },
  mounted () {
          this.$store.dispatch(LIST_ANOS, {}).then(response => {
              this.send_datos.list_anos = response.rest.items
          }, error => {
              this.$store.commit(SET_ERROR, error.statusText)
          })      
    /*  this.$store.dispatch(READ_ESPECIALIDAD, {}).then(response => {

          this.datos_academia = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
*/

  },
  updated() {
    console.log('updated Persona: ')
    //this.refrescar()
  }

}
</script>
